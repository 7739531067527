import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ICart} from '../../shared/interfaces/DTO/cart/cart';
import {ICartInfoResponse} from '../../shared/interfaces/DTO/cart/cartInfoResponse';
import {ICreateCartResponse} from '../../shared/interfaces/DTO/cart/createCartResponse';
import {ICartUpdateRequest} from '../../shared/interfaces/DTO/cart/cartUpdateRequest';
import {ICartCost, IEndCustomerData} from '../../shared/interfaces/DTO/order/order';
import {IAddSparepartPositionRequest} from '../../shared/interfaces/DTO/cart/addSparepartPositionRequest';
import {IAddWorkPositionRequest} from '../../shared/interfaces/DTO/cart/addWorkPositionRequest';
import {IAddFreePositionRequest} from '../../shared/interfaces/DTO/cart/addFreePositionRequest';
import {ICustomPosition} from '../../shared/interfaces/DTO/cart/customPosition';
import {IOrderResult} from '../../shared/interfaces/DTO/order/orderResult';
import {IAddPlaceholderPositionRequest} from '../../shared/interfaces/DTO/cart/addPlaceholderPositionRequest';
import {ICartRequest, ICartResponse} from '../interfaces/cart';
import {IFixedPrices} from '../interfaces/fixedPrices';
import {IAddFixpricePositionRequest} from '../../shared/interfaces/DTO/cart/addFixpricePositionRequest';
import {IAddOeArticlePositionRequest} from '../../shared/interfaces/DTO/cart/addOeArticlePositionRequest';
import {IRawTool} from "../interfaces/raw-tool";
import {IWageRates} from "../../shared/interfaces/wage-rate";
import {IDiscount} from "../../shared/interfaces/DTO/cart/discount";

@Injectable()
export class CartRestService {

    constructor(private http: HttpClient) {
    }

    // Cart

    public getCart(): Observable<ICart> {
        return this.http.get<ICart>(
            `${environment.apiUrl}/cart`
        );
    }

    public getCartCount(): Observable<ICartInfoResponse> {
        return this.http.get<ICartInfoResponse>(
            `${environment.apiUrl}/cart/count`
        );
    }

    public clearCartRest(basketSessionId: string) {
        return this.http.get(
            `${environment.apiUrl}/cart/clear/${basketSessionId}`
        )
    }

    public closeCart(reference: string) {
        return this.http.post(
            `${environment.apiUrl}/cart/order/close`,
            reference
        )
    }


    // Cart list

    public getCartList(cartRequest: ICartRequest): Observable<ICartResponse> {
        return this.http.post<ICartResponse>(
            `${environment.apiUrl}/cart/list`,
            cartRequest
        );
    }

    public selectCart(cartId: string): Observable<boolean> {
        return this.http.put<boolean>(
            `${environment.apiUrl}/cart/select`,
            cartId
        );
    }

    public deleteCart(cartId: string): Observable<boolean> {
        return this.http.put<boolean>(
            `${environment.apiUrl}/cart/delete`,
            cartId
        );
    }

    public renameCart(cartId: string, newName: string): Observable<boolean> {
        return this.http.put<boolean>(
            `${environment.apiUrl}/cart/rename`,
            {
                cartId: cartId,
                name: newName
            }
        );
    }

    public setAcquisitionType(acquisitionType: {acquisitionType: string, label: string}, supplier: string, valid: boolean = true): Observable<boolean> {
        return this.http.put<boolean>(
            `${environment.apiUrl}/cart/setAcquisitionType`,
            {
                labeledAcquisitionType: acquisitionType,
                supplier: supplier,
                valid: valid
            }
        );
    }

    public createCart(): Observable<ICreateCartResponse> {
        return this.http.get<ICreateCartResponse>(
            `${environment.apiUrl}/cart/new`
        );
    }

    public addOeArticleRest(oeBody: IAddOeArticlePositionRequest) {
        return this.http.post(
            `${environment.apiUrl}/cart/add/oearticle`,
            oeBody
        );
    }


    //Discount

    public updateDiscountRest(discount: IDiscount): Observable<ICartCost> {
        return this.http.post<ICartCost>(
            `${environment.apiUrl}/cart/discount`,
            discount
        );
    }



    // Cart positions

    public updateCartDeliveryCostsRest(positionList: ICartUpdateRequest): Observable<ICartCost> {
        return this.http.post<ICartCost>(
            `${environment.apiUrl}/cart/update`,
            positionList
        );
    }

    public addSparepartToCart(article: IAddSparepartPositionRequest): Observable<boolean> {
        return this.http.post<boolean>(
            `${environment.apiUrl}/cart/add/sparepart`,
            article
        );
    }

    public addOeArticleToCart(article: IAddOeArticlePositionRequest): Observable<boolean> {
        return this.http.post<boolean>(
            `${environment.apiUrl}/cart/add/oearticle`,
            article
        );
    }

    public addWorkToCart(work: IAddWorkPositionRequest): Observable<any> {
        return this.http.post<any>(
            `${environment.apiUrl}/cart/add/workposition`,
            work
        );
    }

    public addFreePositionToCart(position: IAddFreePositionRequest): Observable<boolean> {
        return this.http.post<boolean>(
            `${environment.apiUrl}/cart/add/freeposition`,
            position
        );
    }

    public addFixedPositionToCartRest(position: IFixedPrices) {
        return this.http.post<boolean>(
            `${environment.apiUrl}/cart/add/freeposition`,
            position
        );
    }

    public changePos(acquisitionType: string, positionId: number, quantity?: number, sellOutNetPerPiece?: number, description?: string, ipc?: string, supplier?: string, dealerArticleNumber?: string, dealerBrandIdentifier?: string, additionalName?: string, quantityUnit?: string): Observable<boolean> {
        return this.http.put<boolean>(
            `${environment.apiUrl}/cart/change/position`,
            {
                accquisitionType: acquisitionType,
                posId: positionId,
                quantity: quantity,
                customSelloutPriceNetPerPiece: sellOutNetPerPiece,
                ipc: ipc,
                description: description,
                supplier: supplier,
                dealerArticleNumber: dealerArticleNumber,
                dealerBrandIdentifier: dealerBrandIdentifier,
                additionalName: additionalName,
                quantityUnit: quantityUnit
            }
        );
    }

    public deletePos(positionId: number, deleteState?: string): Observable<any> {
        return this.http.post<boolean>(
            `${environment.apiUrl}/cart/delete/position`,
            {
                posId: positionId,
                valid: true,
                type: deleteState
            }
        );
    }

    public getCustomPositions(positionType: string): Observable<ICustomPosition[]> {
        return this.http.get<ICustomPosition[]>(
            `${environment.apiUrl}/custompositions?type=${positionType}`,
        );
    }

    public getWageRateForCart(): Observable<IWageRates> {
        return this.http.get<IWageRates>(
            `${environment.apiUrl}/cart/wageRates`,
        );
    }

    public deleteCustomPosition(positionId: number): Observable<any> {
        return this.http.delete<any>(
            `${environment.apiUrl}/custompositions/${positionId}`
        );
    }

    // Send order-history

    public sendOrder( positions: number[], confirmationMail: string, reference?: string): Observable<IOrderResult> {
        return this.http.post<IOrderResult>(
            `${environment.apiUrl}/cart/order`,
            {
                positionsToBeOrdered: positions,
                reference: reference,
                confirmationMail: confirmationMail
            }
        );
    }

    public toggleCompositeWorkTime(): Observable<ICart> {
        return this.http.get<ICart>(
            `${environment.apiUrl}/cart/toggleCompositeWorkTime`
        );
    }

    public setEndCustomerInformation(endCustomerInformation: IEndCustomerData) {
        return this.http.post(
            `${environment.apiUrl}/cart/setEndCustomerInformation`,
            endCustomerInformation
        )
    }

    public addPlaceholder(placeholder: IAddPlaceholderPositionRequest) {
        return this.http.post(
            `${environment.apiUrl}/cart/add/placeholder`,
            placeholder
        );
    }

    public addFixpricePositionRest(fixpricePosition: IAddFixpricePositionRequest) {
        return this.http.post(
            `${environment.apiUrl}/cart/add/fixedpriceposition`,
            fixpricePosition
        );
    }

    public setCartCommentRest(comment: string) {
        return this.http.post(
            `${environment.apiUrl}/cart/setComment`,
            comment
        );
    }

    public openExternalTool(requestBody: any) {
        return this.http.post(
            `${environment.apiUrl}/tools/external/open`,
            requestBody, {responseType: 'text'}
        );
    }

    public getRawPdf(url: string): Observable<any> {
        return this.http.get(url, {responseType: 'arraybuffer'});
    }

    public getCartContentRest(cartId: string): Observable<ICart> {
        return this.http.get<ICart>(
            `${environment.apiUrl}/cart/${cartId}`
        );
    }


    public packingSlipByMail(orderId: string, mail: string): Observable<any> {
        return this.http.post<any>(
            `${environment.apiUrl}/documents/packing/send-by-mail?orderId=${orderId}`, {
                orderId: orderId,
                sentToMail: mail
            }
        );
    }

    public getRawTools(adcTypeId: number): Observable<IRawTool[]> {
        return this.http.get<IRawTool[]>(
            `${environment.apiUrl}/tecrmi/work-values/cartLabourPositionsRawToolsByCar?adcTypeId=${adcTypeId}`
        );
    }

}
