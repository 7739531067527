import {Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {CartRestService} from '../../../cart/services/cart.rest.service';
import {CartService} from '../../../cart/services/cart.service';
import {UserService} from '../../services/user/user.service';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {itemSelected, submit, visible} from '../../animations/general.animation';
import {ArticlesRestService} from '../../../articles/services/articles.rest.service';
import {EventSourcePolyfill, NativeEventSource} from 'event-source-polyfill';
import {Subscription} from 'rxjs/Rx';
import {Location} from '@angular/common';
import {HelperService} from '../../services/helper.service';
import {IAddSparepartPositionRequest} from '../../interfaces/DTO/cart/addSparepartPositionRequest';
import {CarService} from '../../services/car/car.service';
import {ArticlesService} from '../../../articles/services/articles.service';
import {IAddOeArticlePositionRequest} from '../../interfaces/DTO/cart/addOeArticlePositionRequest';
import {Router} from '@angular/router';
import {MaintenanceService} from '../../../maintenance/services/maintenance.service';
import {WorkService} from '../../../work-data/services/work.service';
import {IArticleSupplier} from "../../interfaces/DTO/IArticleSupplier";
import {finalize} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";

const EventSource = NativeEventSource || EventSourcePolyfill;

@Component({
    selector: 'app-availability',
    templateUrl: './availability.component.html',
    styleUrls: ['./availability.component.scss'],
    animations: [itemSelected, visible, submit],
    host: {
        '(document:click)': 'onOutsideClick($event)',
    }
})
export class AvailabilityComponent implements OnDestroy, OnInit, OnChanges {
    private selectedArticle: any;
    private userSupps: any;
    private supplierList: IArticleSupplier[];
    private specialSupps: any;
    public toCartLoading: number = 0;
    public sellOutGrossPerPiece: any;
    public loadAddToCart: boolean = false;
    public isSelected: boolean [] = [false];
    public showLoadingForCartBtn: boolean = false;

    private eventStream: Subscription;
    @Input() public service: any = undefined;
    @Input() public article: any;

    constructor(private cartRestService: CartRestService,
                public cartService: CartService,
                public userService: UserService,
                private articlesRestService: ArticlesRestService,
                private zone: NgZone,
                private _eref: ElementRef,
                public translate: TranslateService,
                private router: Router,
                private location: Location,
                public helperService: HelperService,
                public carService: CarService,
                public articlesService: ArticlesService,
                public maintenanceService: MaintenanceService,
                public workService: WorkService) {

        this.sellOutGrossPerPiece = 0;
        this.userSupps = this.userService.getSuppliers();
    }

    ngOnInit(): void {
        this.userSupps = this.userService.getSuppliers();
        this.specialSupps = this.userService.getSpecialSuppliers(this.userSupps);
    }

    ngOnChanges(change: any): void {
        if (change.article) {
            this.selectedArticle = this.article;
            if (this.article.requestQuantity && this.article.requestQuantity.quantity) {
                if (!(this.article.requestQuantity.quantity.length > 0)) {
                    this.service.requestQuantity = Math.round((this.article.requestQuantity.amount
                        ? this.article.requestQuantity.amount : 1) / (this.article.vpe ? this.article.vpe : 1));
                    if (this.service.requestQuantity < 1) {
                        this.service.requestQuantity = 1;
                    }
                } else {
                    this.service.requestQuantity = 1;
                }
            }
            this.updateData();
            this.service._toCartPosition = [];
        }
    }

    public onOutsideClick(event: any): void {
        if (!this._eref.nativeElement.contains(event.target) && this.service._toCartPosition) {
            this.service._toCartPosition.supplier = undefined;
        }
    }

    ngOnDestroy(): void {
        this.resetInput();
    }

    public selectSupplier(item: any, index: number): void {
        if (this.isSelected[index] === true) {
            this.isSelected = [];
        } else {
            this.isSelected = [];
            this.changeItem(item, this.service._toCartPosition?.supplier);
            this.isSelected[index] = true;
        }
    }

    public changeItem(item: any, supplier: any): void {
        if (item.supplier !== supplier) {
            this.service.selectSupplier(item);
            this.toCartLoading = 0;
        }
    }

    public addArticleWithoutSupplier(): void {
        if (!this.loadAddToCart) {
            this.loadAddToCart = true;
            const article: IAddSparepartPositionRequest = {
                adcTypeId: this.carService.getAdcTypeId(),
                articleId: this.selectedArticle.id,
                brandId: this.selectedArticle.brandId,
                brandName: this.selectedArticle.brandName,
                description: this.selectedArticle.description,
                ean: this.selectedArticle.ean,
                genArtIds: this.selectedArticle.genArtIds,
                ipc: this.selectedArticle.ipc,
                itemMpId: this.selectedArticle.itemMpId,
                kTypeId: this.carService.getKTypeId(),
                producerName: this.selectedArticle.producerName,
                quantity: this.service.requestQuantity,
                quantityUnit: this.selectedArticle.quantityUnit,
                supplier: null,
                valid: true,
                vin: this.selectedArticle.vin,
                workId: this.selectedArticle.workId,
                additionalName: this.selectedArticle.additionalName
            }

            if (this.articlesService.supplierType === 'AFTER_MARKET') {
                this.cartRestService.addSparepartToCart(article).subscribe(
                    (response) => {
                        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_ADD_TO_CART', 'success');
                        this.cartService.updateCartCount();
                        this.loadAddToCart = false;
                    },
                    (error) => {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_ADD_TO_CART', 'error');
                        this.cartService.updateCartCount();
                        this.loadAddToCart = false;
                    });
            } else if (this.articlesService.supplierType === 'OE') {
                const oeArticle: IAddOeArticlePositionRequest = {
                    adcTypeId: this.carService.getAdcTypeId(),
                    articleId: this.selectedArticle.id,
                    brandName: this.selectedArticle.brandName,
                    description: this.selectedArticle.description,
                    genericArticleIds: this.selectedArticle.genericArticleNumbers,
                    kTypeId: this.carService.getKTypeId(),
                    quantity: this.service.requestQuantity,
                    quantityUnit: this.selectedArticle.quantityUnit,
                    supplier: null,
                    uvp: this.selectedArticle.uvp,
                    valid: true,
                    vin: this.carService.getVin(),
                    producerName: this.selectedArticle.producerName,
                    itemMpId: this.selectedArticle.itemMpId,
                    additionalName: this.selectedArticle.additionalName
                }
                this.cartRestService.addOeArticleToCart(oeArticle).subscribe(
                    (response) => {
                        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_ADD_TO_CART', 'success');
                        this.cartService.updateCartCount();
                        this.loadAddToCart = false;
                    },
                    (error) => {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_ADD_TO_CART', 'error');
                        this.cartService.updateCartCount();
                        this.loadAddToCart = false;
                    });
            }
        }
    }

    private sortSupplierBySortNo(supplier: IArticleSupplier[]): Promise<boolean> {
        return new Promise<boolean>(function (resolve: any) {
            supplier.sort(function (a, b) {
                return a.sortNo - b.sortNo;
            });

            resolve(true);
        });
    }

    // ##FLUX##
    private getData(): void {
        if (this.service.supplierType === 'AFTER_MARKET') {
            this.userService.getSuppliersList().subscribe(
                (supplierList: IArticleSupplier[]) => {
                    this.supplierList = supplierList;

                    this.sortSupplierBySortNo(this.supplierList).then(() => {
                        this.service.supplier = [];
                        for (let x = 0; x < this.supplierList.length; x++) {
                            if (this.userService.getRegularSupplierName(this.supplierList[x])) {
                                this.service.supplier[x] = {};
                                this.addSupplierToService(x, this.supplierList[x])
                            } else if (this.selectedArticle.possibleSuppliers.indexOf(this.supplierList[x].supplier) > -1) {
                                this.service.supplier[x] = {};
                                this.addSupplierToService(this.userSupps.findIndex(e => e.supplier === this.supplierList[x].supplier), this.supplierList[x]);
                            }
                        }
                        this.startAvailabailityFlux();
                    });
                }
            );
        } else if (this.service.supplierType === 'OE') {
            this.articlesRestService.getOeSuppliers().subscribe(
                (suppliers) => {
                    this.supplierList = suppliers;
                    this.userSupps = suppliers

                    this.sortSupplierBySortNo(this.supplierList).then(() => {
                        this.sortSupplierBySortNo(this.userSupps).then(() => {
                            this.specialSupps = this.userService.getSpecialSuppliers(this.userSupps);
                            this.service.supplier = [];
                            for (let x = 0; x < this.supplierList.length; x++) {
                                if (this.userService.getRegularSupplierName(this.supplierList[x])) {
                                    this.service.supplier[x] = {};
                                    this.addSupplierToService(x, this.supplierList[x])
                                } else if (this.selectedArticle.possibleSuppliers.indexOf(this.supplierList[x].supplier) > -1) {
                                    this.service.supplier[x] = {};
                                    this.addSupplierToService(this.userSupps.findIndex(e => e.supplier === this.supplierList[x].supplier), this.supplierList[x])
                                }
                            }
                            this.startOeAvailabilityFlux();
                        });
                    });
                });
        }
    }

    private startAvailabailityFlux(): void {
        const me = this;
        const observable = Observable.create(observer => {
            const eventSource = new EventSource(environment.apiUrl +
                '/article/availability?articleId=' + encodeURIComponent(this.selectedArticle.id) +
                '&requestedQuantity=' + this.service.requestQuantity);
            eventSource.onmessage = x => {
                observer.next(x.data);
            };
            eventSource.onerror = x => {
                eventSource.close();
            };

            return () => {
                eventSource.close();
            };
        });

        this.eventStream = observable.subscribe({
            next: guid => {
                this.zone.run(() => {
                    const temp = JSON.parse(guid);
                    if (this.service.supplier[this.userSupps.findIndex(e => e.supplier === temp.supplier)]
                        && this.service.supplier[this.userSupps.findIndex(e => e.supplier === temp.supplier)] !== temp
                        && this.service.supplier[this.userSupps.findIndex(e => e.supplier === temp.supplier)].supplier === temp.supplier
                        && temp.articleId === this.selectedArticle.id) {
                        this.addSupplierToService(this.userSupps.findIndex(e => e.supplier === temp.supplier), temp)
                    } else {
                        this.checkPossibleSuppliers(temp)
                    }
                });
            },
            error: err => console.error(err),
        });
    }

    private startOeAvailabilityFlux(): void {
        const observable = new Observable(observer => {
            const eventSource = new EventSource(environment.apiUrl +
                '/article/oeavailability?articleId=' + encodeURIComponent(this.selectedArticle.id) +
                '&requestedQuantity=' + this.service.requestQuantity + '&uvp=' + this.selectedArticle.uvp);
            eventSource.onmessage = x => {
                observer.next(x.data);
            };
            eventSource.onerror = x => {
                eventSource.close();
            };

            return () => {
                eventSource.close();
            };
        });

        this.eventStream = observable.subscribe({
            next: guid => {
                this.zone.run(() => {
                    const temp = JSON.parse(guid.toString());
                    if (this.service.supplier[this.userSupps.findIndex(e => e.supplier === temp.supplier)]
                        && this.service.supplier[this.userSupps.findIndex(e => e.supplier === temp.supplier)] !== temp
                        && this.service.supplier[this.userSupps.findIndex(e => e.supplier === temp.supplier)].supplier === temp.supplier) {
                        this.addSupplierToService(this.userSupps.findIndex(e => e.supplier === temp.supplier), temp)
                    } else {
                        this.checkPossibleSuppliers(temp)
                    }
                });
            },
            error: err => console.error(err)
        });
    }

    public checkPossibleSuppliers(item: any): void {
        if (this.userService.getSuppliersName(this.specialSupps).indexOf(item.supplier) > -1) {
            if (this.selectedArticle.possibleSuppliers.indexOf(item.supplier) > -1) {
                this.addSupplierToService(this.userSupps.findIndex(e => e.supplier === item.supplier), item)
            }
            for (let i = 0; i < item.schedule.length; i++) {
                if (item.schedule[i].availability !== 'NOT_AVAILABLE' &&
                    item.schedule[i].availability !== 'CONNECTION_BROKEN' &&
                    item.schedule[i].availability !== 'MISSING_ARTICLE_INFORMATION' &&
                    item.schedule[i].availability !== 'INVALID_CREDENTIALS') {
                    this.addSupplierToService(this.service.supplier.length, item)
                }
            }
        }
    }

    public addSupplierToService(index: number, supplier: any): void {
        let articleId = undefined;
        switch (this.getRelevantSearch()) {
            case 'AFTER_MARKET':
                articleId = this.articlesService.selectedArticle.id;
                break;
            case 'OE':
                articleId = this.articlesService.selectedArticle.id;
                break;
            case 'MAINTENANCE':
                articleId = this.maintenanceService.selectedArticle.id;
                break;
            case 'WORK':
                articleId = this.workService.selectedArticle.id;
                break;
        }
        if (index > -1 &&
            !this.service.supplier.find(e => e === supplier) &&
            (!supplier.articleId || supplier.articleId === articleId)) {
            this.service.supplier[index] = supplier;
        }
    }

    public getTotalPrice(): void {
        let price = undefined;
        if (this.service.supplier && this.service.supplier.length > 0) {
            this.service.supplier.forEach((supplier) => {
                if (supplier.supplier === this.service._selectedSupplier) {
                    price = supplier.sellInNetTotalFormatted;
                }
            })
        }
        return price;
    }

    private updateData(): void {
        if (this.selectedArticle) {
            this.getData();
        }
    }

    private getRelevantSearch(): any {
        if (this.articlesService.selectedArticle) {
            return this.service.supplierType;
        } else if (this.maintenanceService.selectedArticle) {
            return 'MAINTENANCE'
        } else if (this.workService.selectedArticle) {
            return 'WORK'
        }
    }

    private resetInput(): void {
        this.service.requestQuantity = 1;
        this.service.supplier = [];
    }

    public inputLoadAvailability(event: any): void {
        if (event > 0 && this.service.requestQuantity !== event) {
            this.service.requestQuantity = event;
            if (this.selectedArticle !== this.service.selectedArticle) {
                this.selectedArticle = this.service.selectedArticle;
            }
            this.getData();
        }
    }

    public checkReplacement(position: IAddSparepartPositionRequest, $event: any): void {
        $event.preventDefault();
        $event.stopPropagation();
        this.showLoadingForCartBtn = true;

        position.quantity = this.service.requestQuantity;
        position.workId = this.selectedArticle.workId;
        position.itemMpId = this.selectedArticle.itemMpId;
        position.vin = this.carService.getVin();
        position.producerName = this.carService.getCarProducerName();
        position.genArtIds = this.selectedArticle.genArtIds;
        position.additionalName = this.selectedArticle.additionalName;
        position.adcTypeId = this.carService.getAdcTypeId();
        position.kTypeId = this.carService.getKTypeId();

        let posInCart: any = undefined;

        if (this.location.path().indexOf('/work') > -1 || this.location.path().indexOf('/maintenance/plan') > -1) {
            this.cartService.currentCart.positions.filter(
                cartPosition => (
                    cartPosition.type === 'PLACEHOLDER' || cartPosition.type === 'SPAREPART')
            ).forEach((pos) => {
                if (pos.itemMpId === position.itemMpId && pos.workId === position.workId) {
                    posInCart = pos;
                }
            });
        }

        if (this.location.path().indexOf('/article-selection') > -1) {
            this.cartService.currentCart.positions.filter(cartPosition => (cartPosition.type === 'PLACEHOLDER' || cartPosition.type === 'SPAREPART')).forEach((pos) => {
                if (position.genArtIds && position.genArtIds.length > 0 && pos.genericArticleIds.includes(position.genArtIds[0])) {
                    posInCart = pos;
                }
            })
        }

        if (posInCart) {
            this.toCartLoading = 1;
            this.cartService.replacePos(position, posInCart);
            this.showLoadingForCartBtn = false;
        } else {
            this.addToCart(position).then( () => this.showLoadingForCartBtn = false);
        }
    }


    public addToCart(_toCartPosition: IAddSparepartPositionRequest): Promise<void> {
        this.toCartLoading = 1;
        return new Promise((resolve) => {
            if (this.articlesService.supplierType === 'AFTER_MARKET') {
                this.cartRestService.addSparepartToCart(_toCartPosition).pipe(
                    finalize(() => {
                        resolve();
                    })
                ).subscribe(
                    (response) => {
                        this.handleResponse(response, _toCartPosition);
                    },
                    (error) => {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_ADD_TO_CART', 'error');
                        this.cartService.updateCartCount();
                        this.toCartLoading = 2;
                    }
                );
            } else if (this.articlesService.supplierType === 'OE') {
                const oeArticle: IAddOeArticlePositionRequest = {
                    adcTypeId: _toCartPosition.adcTypeId,
                    articleId: _toCartPosition.articleId,
                    brandName: _toCartPosition.brandName,
                    description: _toCartPosition.description,
                    genericArticleIds: this.selectedArticle.genericArticleIds,
                    kTypeId: _toCartPosition.kTypeId,
                    quantity: _toCartPosition.quantity,
                    quantityUnit: _toCartPosition.quantityUnit,
                    supplier: _toCartPosition.supplier,
                    uvp: this.selectedArticle.uvp,
                    valid: _toCartPosition.valid,
                    vin: _toCartPosition.vin,
                    producerName: _toCartPosition.producerName,
                    itemMpId: this.selectedArticle.itemMpId,
                    additionalName: _toCartPosition.additionalName
                };
                this.cartRestService.addOeArticleToCart(oeArticle).pipe(
                    finalize(() => {
                        resolve();
                    })
                ).subscribe(
                    (response) => {
                        this.handleResponse(response, _toCartPosition);
                    },
                    (error) => {
                        this.helperService.showNotification('TOAST_MESSAGES.ERROR_ADD_TO_CART', 'error');
                        this.cartService.updateCartCount();
                        this.toCartLoading = 2;
                    }
                );
            } else {
                resolve();
            }
        });
    }

    public handleResponse(response: boolean, _toCartPosition: any): void {
        this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_ADD_TO_CART', 'success');
        this.cartService.updateCartCount();
        this.toCartLoading = 0;
        if (this.router.url.includes('/cart/article-selection')) {
            this.cartService.updateCart(false, false, false, false);
        }
        setTimeout(() => {
            if (this.service._toCartPosition && this.service._toCartPosition.supplier === _toCartPosition.supplier) {
                this.service._toCartPosition.supplier = undefined;
            }
        }, 1500);
    }

    public findSupplierName(code: string): string {
        const supp = this.supplierList.find(x => x.supplier === code)
        if (supp) {
            return supp.name;
        } else {
            return ''
        }
    }

    public areAllElementsInAvailabilityArrayWithState(availabilityArray: IAvail[], state: string): boolean {
        return availabilityArray?.every((avail: IAvail) => avail.availability === state);
    }
}


export interface IAvail {
    availability: string;
    deliveryDate: string;
    deliveryTime: string;
    depotCode: string;
    depotName: string;
    quantity: number;
    tour: string;
}
