<div class="home checkout">
    <div class="content">

        <div class="left-panel no-float ">
        </div>

        <div class="center-panel no-float">
            <app-default-loading-page *ngIf="cartService.cartLoading"></app-default-loading-page>

            <div class="heading blue" style="line-height: 1rem;">
                <!--<div class="custom-dropdown right" *ngIf="(!response || (response && !response.submitted) && cartService.currentCart)">-->
                <!--<select (change)="display()" class="pull-right">-->
                <!--<option [value]="'supplier'" [selected]="bySupplier">{{'CART.DISPLAY.BY_SUPPLIER' | translate}}</option>-->
                <!--<option [value]="'article'"  [selected]="byArticle" >{{'CART.DISPLAY.BY_ARTICLE' | translate}}</option>-->
                <!--</select>-->
                <!--</div>-->
            </div>

            <app-success [@dialog]="response && response.submitted"
                         [orderId]="response.orderId"
                         *ngIf="response && response.submitted"></app-success>

            <app-retry [errorType]="response.checkAndRetryReason" (ok)="reloadCart()"
                       [@dialog]="response && response.checkAndRetry"
                       *ngIf="response && response.checkAndRetry"></app-retry>


            <div class="panel-content center-content"
                 *ngIf="(!response || (response && !response.submitted) && cartService.currentCart)">

                <ng-container *ngFor="let supplier of bySupplier | cat">
                    <div *ngIf="hasActiveArticle(supplier)">
                        <div class="card-title row">
                            <div class="col-8">
                                <img *ngIf="supplier.key" class="img-fluid" [supplier]="supplier.key"
                                     [logo-color]="true" title="{{supplier.value[0].supplierName}}"/>
                            </div>
                            <div class="col-4">
                                <div class="custom-dropdown mb-0">
                                    <select  [compareWith]="compareAcquisitionType" (ngModelChange)="changeAcquisition(supplier, $event)"
                                            [ngModel]="cartService.currentCart.supplierInformation[supplier.key]?.acquisitionInformation?.selectedAcquisitionType">
                                        <option *ngFor="let c of cartService.currentCart.supplierInformation[supplier.key]?.acquisitionInformation?.possibleAcquisitionTypes; let i = index"
                                                [ngValue]="{acquisitionType: c.acquisitionType, label: c.label}">
                                            <span *ngIf="c.label && c.label.length && c.label.length >0">
                                                 {{c.label}}
                                            </span>
                                            <span *ngIf="!c.label || !c.label.length || c.label.length ==0">
                                                  {{'CART.ACQUISITION_TYPES.' + c.acquisitionType | translate}}
                                            </span>
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                             *ngIf="cartService.currentCart.supplierInformation[supplier.key].acquisitionInformation?.showDeliveryCostWarning">
                            <div class="col alert alert-primary text-center">{{'CART.DELIVERY_COSTS_WARNING' | translate}}</div>
                        </div>
                        <div class="">
                            <table class="simple-table">
                                <thead>
                                <tr>
                                    <th class="normalText">
                                        <div>{{ 'CART.MANUFACTURER' | translate }}</div>
                                        {{ 'CART.ARTICLE_NUMBER' | translate }}
                                    </th>
                                    <th>
                                        {{'CART.DEALER_ARTICLE_NO' | translate}}
                                    </th>
                                    <th class="normalText">{{ 'CART.DESCRIPTION' | translate }}</th>
                                    <th class="text-right">
                                        <div>{{ userService.sale? ('CART.SINGLE_PRICE_SALE' | translate ): ('CART.SINGLE_PRICE' | translate) }}</div>
                                        <div *ngIf="showSellinPrices">({{ 'CART.SELLIN_PRICE' | translate }})
                                        </div>
                                    </th>
                                    <th class="text-center">{{ 'CART.AMOUNT' | translate }}</th>
                                    <th class="text-right">
                                        {{ userService.sale ? ('CART.TOTAL_PRICE_SALE' | translate) : ('CART.TOTAL_PRICE' | translate) }}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>

                                <ng-container *ngFor="let position of supplier.value">
                                    <tr *ngIf="position.selected">
                                        <td>
                                            <div class="brand" *ngIf="position.brandName">{{ position.brandName }}
                                            </div>
                                            <div class="ipc">{{ position.ipc }}</div>
                                        </td>
                                        <td>
                                            {{position.dealerArticleNumber}}
                                        </td>
                                        <td>
                                            {{ position.description }}
                                        </td>
                                        <td class="text-right">
                                            {{ position.sellInNetPerPieceFormatted}}
                                        </td>
                                        <td class="text-center">
                                            {{verifyQuantity(position)}}
                                        </td>
                                        <td class="text-right">
                                            {{ position.sellInNetTotalFormatted}}
                                        </td>
                                    </tr>
                                </ng-container>

                                <tr class="shipping"
                                    *ngIf="cartService.currentCart.cartCostDTO.deliveryCosts[supplier.key]">
                                    <td colspan="2"></td>
                                    <td>
                                        {{'CART.DELIVERY_COSTS' | translate}}
                                    </td>
                                    <td class="text-right" colspan="3">
                                        {{cartService.currentCart.cartCostDTO.deliveryCosts[supplier.key].valueFormmatted}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </ng-container>
                <div class="" *ngFor="let articles of byArticle | cat">
                    <div class="card-title">
                        <h4>{{articles.value[0].brandName}} {{articles.value[0].ipc}}<br/>
                            <small> {{articles.value[0].description}}</small>
                        </h4>
                        <div class="clearfix"></div>
                    </div>
                    <div class="">
                        <table class="simple-table">
                            <thead>
                            <tr>
                                <th class="normalText" width="100px">{{'CART.DEALER' | translate}}</th>
                                <th class="normalText">
                                    {{ 'CART.ARTICLE_NUMBER' | translate }}
                                </th>
                                <th class="text-right">
                                    <div>{{ userService.sale? ('CART.SINGLE_PRICE_SALE' | translate ): ('CART.SINGLE_PRICE' | translate) }}</div>
                                    <div *ngIf="showSellinPrices">({{ 'CART.SELLIN_PRICE' | translate }})
                                    </div>
                                </th>
                                <th class="text-center">{{ 'CART.AMOUNT' | translate }}</th>
                                <th class="text-right">
                                    {{ userService.sale ? ('CART.TOTAL_PRICE_SALE' | translate) : ('CART.TOTAL_PRICE' | translate) }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>

                            <ng-container *ngFor="let position of articles.value">
                                <tr *ngIf="position.selected">
                                    <td>
                                        <img *ngIf="position.supplier" class="img-fluid"
                                             [supplier]="position.supplier" [logo-color]="true"
                                             title="{{position.supplier}}"/>
                                    </td>
                                    <td>
                                        {{position.dealerArticleNumber}}
                                    </td>
                                    <td class="text-right">
                                        {{ position.sellInNetPerPieceFormatted }}
                                    </td>
                                    <td class="text-center">
                                        {{verifyQuantity(position)}} {{ 'CART.' + position.quantityUnit | translate }}
                                    </td>
                                    <td class="text-right">
                                        {{ position.sellInNetTotalFormatted }}
                                    </td>
                                </tr>
                            </ng-container>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!response || (response && !response.submitted)" class="right-panel no-float split-panel">
            <div class="top-section">

                <div class="panel-content">
                    <div class="inner-content">

                        <div class="card" *ngIf="summary">

                            <div style="padding: 1rem;">
                                <app-material-form-input
                                        [label]="'CART.REFERENCE' | translate"
                                        [type]="'text'"
                                        [disabled]="false"
                                        [(text)]="reference">
                                </app-material-form-input>
                            </div>

                            <div class="mailInput"
                                 *ngIf="userService?.accountData?.settings['orderConfirmationMail'].value === true">
                                <app-material-form-input [label]="'ORDER.CONFIRMATION_MAIL' | translate"
                                                         [type]="'text'"
                                                         [disabled]="false"
                                                         [(text)]="orderConfirmationMail">
                                </app-material-form-input>
                            </div>

                            <table class="simple-table text-right">
                                <tbody>
                                <tr>
                                    <td colspan="2">
                                        <b>{{ cartService.checkedCount }} {{ 'GENERAL.ARTICLE' | translate }}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">
                                        {{'CART.SUM_NET' | translate}}:
                                    </td>
                                    <td class="text-right">
                                        {{cartService.currentCart.cartCostDTO.sellInNetTotalFormatted}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">
                                        {{'CART.VAT' | translate}} {{cartService.currentCart.cartCostDTO.vat}}%:
                                    </td>
                                    <td class="text-right">
                                        {{cartService.currentCart.cartCostDTO.sellInVatTotalFormatted}}
                                    </td>
                                </tr>
                                <ng-container *ngIf="getDeliveryCostsKeys().length > 0">
                                    <tr>
                                        <td colspan="2">
                                            <b>{{'CART.DELIVERY_COSTS' | translate}}</b>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let position of getDeliveryCostsKeys()">
                                        <td class="text-left">
                                            {{position}}
                                        </td>
                                        <td class="text-right">
                                            {{cartService?.currentCart?.cartCostDTO.deliveryCosts[position].valueFormmatted}}
                                        </td>
                                    </tr>
                                </ng-container>
                                <tr class="cartSum">
                                    <td class="text-left">
                                        {{'CART.SUM' | translate}}:
                                    </td>
                                    <td class="text-right">
                                        {{cartService.currentCart.cartCostDTO.sellInGrossTotalWithDeliveryCostsFormatted}}
                                    </td>
                                </tr>
                                <tr class="cartSum" *ngIf="cartService.currentCart.cartDiscount">
                                    <td class="text-left">
                                        {{ 'CART.SUM_WITH_DISCOUNT' | translate }}:
                                    </td>
                                    <td class="text-right">
                                        {{ cartService?.currentCart?.cartCostDTO.sellOutGrossTotalWithDiscountFormatted }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <button class="order-btn" *ngIf="userService.allow('ORDER')" (click)="sendOrder()"
                                    style="margin: 1rem">
                                <i *ngIf="sendOrderLoader" class="fa fa-spinner"
                                   [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i>
                                <span *ngIf="!sendOrderLoader">{{'PROMPTS.BUTTONS.SUBMIT_ORDER' | translate}}</span>
                            </button>
                            <div *ngIf="!userService.allow('ORDER')" tooltip tooltipClass="centerToolTip"
                                 tooltipPosition="center" tooltipContent="{{'CART.TOOLTIP_NO_RIGHTS' | translate}}">
                                <button class="order-btn" disabled style="margin: 1rem">
                                    <span *ngIf="!sendOrderLoader">{{'PROMPTS.BUTTONS.SUBMIT_ORDER' | translate}}</span>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

