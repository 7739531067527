import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {CartService} from '../services/cart.service';
import {UserService} from '../../shared/services/user/user.service';
import {PdfService} from '../../shared/services/pdf.service';
import {TranslateService} from '@ngx-translate/core';
import {fadeOfferToggle} from '../../shared/animations/general.animation';
import {Router} from '@angular/router';
import {OrderHistoryService} from '../../admin/company/order-history/services/order-history.service';
import {Location} from '@angular/common';
import {CarService} from '../../shared/services/car/car.service';
import {CustomerDataService} from '../../shared/services/customerData.service';
import {HelperService} from '../../shared/services/helper.service';
import {IData} from '../../shared/interfaces/data';

@Component({
    selector: 'app-offer',
    templateUrl: './offer.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [fadeOfferToggle]
})
export class OfferComponent implements OnInit, AfterViewInit {

    public form: any = {};
    public showPdf: boolean = false;
    public pdfLoading: boolean = false;
    public pdf: any;
    private localDataHolder: IData;
    public hideArticleNumbers: boolean = false;

    constructor(public customerDataService: CustomerDataService,
                public cartService: CartService,
                public pdfService: PdfService,
                public userService: UserService,
                public translate: TranslateService,
                public location: Location,
                private router: Router,
                public orderHistoryService: OrderHistoryService,
                private carService: CarService,
                private changeDetection: ChangeDetectorRef,
                public helperService: HelperService) {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        if (this.router.url.indexOf('company-admin') > -1) {
            if (!this.orderHistoryService.selectedHistory || !this.orderHistoryService.selectedCart) {
                this.router.navigate([this.router.url.substring(0, this.router.url.lastIndexOf('/'))]);
            }
        }

        if (this.carService.selectedCar) {
            this.pdfService.client.carName = this.carService.selectedCar.displayName;
        }

        this.carService.selectedCarChanged$.subscribe((car) => {
            if (car) {
                this.pdfService.client.carName = this.carService.selectedCar.displayName;
            }
        });
    }

    ngOnInit() {
        this.pdfService.updateDmsData();
        this.cartService.onCartChange.subscribe((step) => {
            if (this.showPdf) {
                this.createPdf();
            }
        });

        if (this.cartService.isOrder()) {
            this.createPdf();
        }
    }


    ngAfterViewInit() {
        this.localDataHolder = this.helperService.recursiveDeepCopy(this.customerDataService.getDataHolder());
        if ((this.customerDataService.getDataHolder()) === undefined) {
            this.customerDataService.resetDataHolder();
        }
        if (this.changeDetection && !this.changeDetection['destroyed']) {
            this.changeDetection.detectChanges();
        }
    }


    public generatePdf() {
        const currentCart = this.cartService.currentCart,
            tableHeaderElements: { text: string; style: string; }[] = [],
            bodyElements: any[] = [],
            docDefinition: any = this.pdfService.buildDocDefinition(true, null, false);


        if (!this.hideArticleNumbers) {
            tableHeaderElements.push({text: this.translate.instant('PRINT_VIEW.ARTICLE.ARTICLE_NO'), style: 'header'});
        } else {
            tableHeaderElements.push({text: '', style: 'header'});
        }

        tableHeaderElements.push(
            {text: this.translate.instant('PRINT_VIEW.ARTICLE.DESC'), style: 'header'},
            {text: this.translate.instant('PRINT_VIEW.ARTICLE.QUANTITY'), style: 'centeredHeader'},
            {text: this.translate.instant('PRINT_VIEW.ARTICLE.UNIT'), style: 'centeredHeader'},
            {text: this.translate.instant('PRINT_VIEW.ARTICLE.PRICE_PIECE'), style: 'rightHeader'},
            {text: this.translate.instant('PRINT_VIEW.ARTICLE.PRICE_FULL'), style: 'rightHeader'});


        // date and reference fields

        const today = new Date();
        docDefinition.content.push(
            {
                text: this.translate.instant('GENERAL.DOCS.OFFER') + ' ' + (this.pdfService.client.offerNo ? this.pdfService.client.offerNo : ''),
                fontSize: 14,
                margin: [0, 40, 0, 5]
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        text: this.translate.instant('GENERAL.DATE') + ':',
                        width: 60
                    },
                    {
                        text: today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear(),
                        width: 70
                    }
                ],
                fontSize: 10
            },
            {
                alignment: 'justify',
                columns: [
                    {
                        text: this.translate.instant('PRINT_VIEW.FORM.REFERENCE') + ':',
                        width: 60
                    },
                    {
                        text: (this.pdfService.client.reference ? this.pdfService.client.reference : ''),
                        width: 300
                    }
                ],
                fontSize: 10,
                margin: [0, 0, 0, 5]
            }
        );

        // car data
        docDefinition.content.push({
            table: {
                widths: ['*'],
                body: [
                    [
                        {
                            text: [
                                (this.customerDataService.getNumberplate() ? this.translate.instant('PRINT_VIEW.FORM.NUMBER_PLATE') + ': ' +
                                    this.customerDataService.getNumberplate().toUpperCase() : ''),
                                '     ',
                                (this.customerDataService.getHsn() ? this.translate.instant('PRINT_VIEW.FORM.KBA_NO') + ': ' +
                                    this.customerDataService.getHsn().toUpperCase() + ' ' + (this.customerDataService.getTsn() ? this.customerDataService.getTsn().toUpperCase() : '')
                                    : (this.carService.getHsnTsn() ? this.translate.instant('PRINT_VIEW.FORM.KBA_NO') + ': ' + this.carService.getHsnTsn() : '')),
                                '     ',
                                (this.pdfService.client.carName ? this.translate.instant('PRINT_VIEW.FORM.CAR_NAME') + ': ' +
                                    this.pdfService.client.carName : '')
                            ]
                        }
                    ]
                ]
            },
            style: 'carData',
        });

        // articles table

        bodyElements.push(tableHeaderElements);

        for (let i = 0; i < currentCart.positions.length; i++) {
            bodyElements.push([
                ((currentCart.positions[i].type !== 'LABOUR') && !this.hideArticleNumbers ? currentCart.positions[i].ipc : ''),
                currentCart.positions[i].description + ' ' + currentCart.positions[i].additionalName,
                {text: currentCart.positions[i].quantity.toString(), alignment: 'center'},
                {
                    text: this.translate.instant('GENERAL.UNITS.' + currentCart.positions[i].quantityUnit),
                    alignment: 'center'
                },
                {
                    text: currentCart.positions[i].sellOutNetPerPieceFormatted,
                    alignment: 'right'
                },
                {
                    text: currentCart.positions[i].sellOutNetTotalFormatted,
                    alignment: 'right'
                }
            ])


        }

        let width = ['16%', '37%', '10%', '10%', '12%', '15%'];

        if (this.hideArticleNumbers) {
            width = ['0%', '53%', '10%', '10%', '12%', '15%'];
        }

        docDefinition.content.push(
            {
                style: 'cartTable',
                table: {
                    headerRows: 1,
                    widths: width,
                    body: bodyElements
                },
                layout: {
                    hLineColor: 'white',
                    vLineColor: 'white'
                }
            }
        );

        let pricesBody = [];
        if (this.cartService.currentCart.cartCostDTO.sellOutNetTotalWithDiscountFormatted) {
            pricesBody =  [
                [
                    {
                        text: this.translate.instant('CART.SUM'),
                        bold: true
                    },
                    {
                        text: (currentCart.cartCostDTO.sellOutNetTotalFormatted ? currentCart.cartCostDTO.sellOutNetTotalFormatted : ''),
                        alignment: 'right'
                    }
                ],
                [
                    {
                        text: this.translate.instant('CART.DISCOUNT'),
                        bold: true
                    },
                    {
                        text: (currentCart.cartCostDTO.discountAmountFormatted ? currentCart.cartCostDTO.discountAmountFormatted : ''),
                        alignment: 'right'
                    }
                ],
                [
                    {
                        text: this.translate.instant('CART.SUM_NET_WITH_DISCOUNT'),
                        bold: true
                    },
                    {
                        text: (currentCart.cartCostDTO.sellOutNetTotalWithDiscountFormatted ? currentCart.cartCostDTO.sellOutNetTotalWithDiscountFormatted : ''),
                        alignment: 'right'
                    }
                ],
                [
                    {
                        text: this.translate.instant('CART.VAT') + ' (' + currentCart.cartCostDTO.vat + '%)',
                        bold: true
                    },
                    {
                        text: (currentCart.cartCostDTO.sellOutVatTotalTotalFormatted ? currentCart.cartCostDTO.sellOutVatTotalTotalFormatted : ''),
                        alignment: 'right'
                    }
                ],
                [
                    {text: this.translate.instant('CART.TOTAL_PRICE'), bold: true},
                    {
                        text: (currentCart.cartCostDTO.sellOutGrossTotalFormatted ? currentCart.cartCostDTO.sellOutGrossTotalFormatted : ''),
                        alignment: 'right',
                    }
                ],
                    [
                    {text: this.translate.instant('CART.SUM_WITH_DISCOUNT'), bold: true, fontSize: 16},
                        {
                            text: (currentCart.cartCostDTO.sellOutGrossTotalWithDiscountFormatted ? currentCart.cartCostDTO.sellOutGrossTotalWithDiscountFormatted : ''),
                            alignment: 'right',
                            fontSize: 16,
                            bold: true
                        }
                    ]
            ]
        } else {
            pricesBody =  [
                [
                    {
                        text: this.translate.instant('CART.SUM'),
                        bold: true
                    },
                    {
                        text: (currentCart.cartCostDTO.sellOutNetTotalFormatted ? currentCart.cartCostDTO.sellOutNetTotalFormatted : ''),
                        alignment: 'right'
                    }
                ],
                [
                    {
                        text: this.translate.instant('CART.VAT') + ' (' + currentCart.cartCostDTO.vat + '%)',
                        bold: true
                    },
                    {
                        text: (currentCart.cartCostDTO.sellOutVatTotalTotalFormatted ? currentCart.cartCostDTO.sellOutVatTotalTotalFormatted : ''),
                        alignment: 'right'
                    }
                ],
                [
                    {text: this.translate.instant('CART.TOTAL_PRICE'), bold: true, fontSize: 16},
                    {
                        text: (currentCart.cartCostDTO.sellOutGrossTotalFormatted ? currentCart.cartCostDTO.sellOutGrossTotalFormatted : ''),
                        alignment: 'right',
                        fontSize: 16,
                        bold: true
                    }
                ]
            ]
        }

        docDefinition.content.push(
            {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 50, y2: 5, lineWidth: 1}]},
            {
                style: 'pricesTable',
                table: {
                    widths: [120, 100],
                    body: pricesBody,
                    alignment: 'right'
                },
                layout: 'noBorders'
            }
        );

        if (this.cartService.currentCart.comment && this.cartService.currentCart.comment.length > 0) {
            docDefinition.content.push({
                table: {
                    widths: ['*'],
                    body: [
                        [
                            {
                                text: this.cartService.currentCart.comment,
                            }
                        ]
                    ]
                },
                style: 'footer',
                layout: {
                    hLineColor: '#FFFFFF',
                    vLineColor: '#FFFFFF'
                }
            });
        }

        if (this.userService && this.userService.accountData && this.userService.accountData.settings && this.userService.accountData.settings['footnote']) {
            docDefinition.content.push({
                table: {
                    widths: ['*'],
                    body: [
                        [
                            {
                                text: this.userService.accountData.settings['footnote'].value,
                            }
                        ]
                    ]
                },
                style: 'footer',
                layout: {
                    hLineColor: '#FFFFFF',
                    vLineColor: '#FFFFFF'
                }
            });
        }


        docDefinition.content.push({
            table: {
                body: [
                    [
                        {
                            text: this.translate.instant('PRINT_VIEW.OFFER_INFO'),
                            alignment: 'center'

                        }
                    ],
                ]
            },
            layout: {
                hLineColor: '#666',
                vLineColor: '#666'
            },
            style: 'carData'
        });
        return JSON.stringify(docDefinition);
    }

    public createPdf() {
        if (!this.cartService.isOrder()) {
            this.customerDataService.setDataHolder(this.localDataHolder);
        }
        this.showPdf = true;
        this.pdfLoading = true;
        setTimeout(() => {
            this.getPdf(JSON.parse(this.generatePdf())).then(
                (pdf: any) => {
                    this.pdfLoading = false;
                    this.pdf = pdf;
                },
                (error: any) => {
                    console.log(error);
                    this.pdfLoading = false;
                }
            );
        }, 100);
    }

    public downloadPdf() {
        return pdfMake.createPdf(JSON.parse(this.generatePdf())).download();
    }

    public printPdf() {
        return pdfMake.createPdf(JSON.parse(this.generatePdf())).print();
    }

    public getPdf(docDefinition: any) {
        return <any>new Promise(function (resolve: any, reject: any) {
            pdfMake.createPdf(docDefinition, 'filename').getBase64(function (pdfBase64: any) {
                let raw = atob(pdfBase64);
                let uint8Array = new Uint8Array(raw.length);
                for (let i = 0; i < raw.length; i++) {
                    uint8Array[i] = raw.charCodeAt(i);
                }
                resolve(uint8Array);
            });
        });
    }

    public getDataHolder() {
        return this.localDataHolder;
    }

    public getMileage() {
        if (this.localDataHolder) {
            return this.localDataHolder.mileage;
        }
    }

    public getNumberplate() {
        if (this.localDataHolder) {
            return this.localDataHolder.numberplate;
        }
    }

    public getCustomerLastName() {
        if (this.localDataHolder) {
            return this.localDataHolder.customerLastName;
        }
    }

    public getCustomerFirstName() {
        if (this.localDataHolder) {
            return this.localDataHolder.customerFirstName;
        }
    }

    public getCustomerAddress() {
        if (this.localDataHolder) {
            return this.localDataHolder.customerAddress;
        }
    }

    public getCustomerCity() {
        if (this.localDataHolder) {
            return this.localDataHolder.customerCity;
        }
    }

    public getCustomerEmail() {
        if (this.localDataHolder) {
            return this.localDataHolder.customerEmail;
        }
    }

    public getCustomerZip() {
        if (this.localDataHolder) {
            return this.localDataHolder.customerZip;
        }
    }

    public getOrderNumber() {
        if (this.localDataHolder) {
            return this.localDataHolder.orderNumber;
        }
    }

    public getYear() {
        if (this.localDataHolder) {
            return this.localDataHolder.year;
        }
    }

    public setDataHolder(dataHolder: IData) {
        if (dataHolder !== this.localDataHolder) {
            this.localDataHolder = dataHolder;
        }
    }

    public setMileage(mileage: number) {
        if (this.localDataHolder.mileage !== mileage) {
            this.localDataHolder.mileage = mileage;
        }
    }

    public setNumberplate(numberplate: string) {
        if (this.localDataHolder.numberplate !== numberplate) {
            this.localDataHolder.numberplate = numberplate;
        }
    }

    public setCustomerLastName(customerLastName: string) {
        if (this.localDataHolder.customerLastName !== customerLastName) {
            this.localDataHolder.customerLastName = customerLastName;
        }
    }

    public setCustomerFirstName(customerFirstName: string) {
        if (this.localDataHolder.customerFirstName !== customerFirstName) {
            this.localDataHolder.customerFirstName = customerFirstName;
        }
    }

    public setCustomerAddress(customerAddress: string) {
        if (this.localDataHolder.customerAddress !== customerAddress) {
            this.localDataHolder.customerAddress = customerAddress;
        }
    }

    public setCustomerCity(customerCity: string) {
        if (this.localDataHolder.customerCity !== customerCity) {
            this.localDataHolder.customerCity = customerCity;
        }
    }

    public setCustomerEmail(customerEmail: string) {
        if (this.localDataHolder.customerEmail !== customerEmail) {
            this.localDataHolder.customerEmail = customerEmail;
        }
    }

    public setCustomerZip(customerZip: string) {
        if (this.localDataHolder.customerZip !== customerZip) {
            this.localDataHolder.customerZip = customerZip;
        }
    }

    public setOrderNumber(orderNumber: string) {
        if (this.localDataHolder.orderNumber !== orderNumber) {
            this.localDataHolder.orderNumber = orderNumber;
        }
    }

    public setYear(year: Date) {
        if (this.localDataHolder.year !== year) {
            this.localDataHolder.year = year;
        }
    }

}
